.App {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.containerCard {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  filter: drop-shadow(0px 2.186274528503418px 4.372549057006836px rgba(0, 0, 0, 0.28));
  background-color: rgb(255, 255, 255);
}

input {
  max-width: 100px;
  height: 28px;
}

.headerCard {
  display: flex;
}

.bodyCard {
  padding-left: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80%;
}

.onFlex {
  flex: 1;
  display: flex;
}

.text {
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  margin: 0;
  justify-content: center;
}

.textResult {
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
  border-radius: 52.243px;
  border-width: 0;
  background: rgba(13, 169, 255, 0.09);
  padding: 5px;
}

hr {
  opacity: 0.4;
}


/* animacao load */
.loader {
  width: 350px;
  height: 30px;
  background-color: #f3f3f3;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  border: 1px solid black
}

#loader-container {
  position: fixed;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}

.loader-bar {
  height: 100%;
  background-color: #00A443; 
  width: 0; 
  border-radius: 20px;
  transition: width 0.5s linear; 
}

.textLoad {
  position: absolute;
  width: 100%;
  z-index: 1;
  text-align: center;
  top: 150%;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: bold;
}

@keyframes load {
  0% {
      transform: translateX(-100%);
  }
  100% {
      transform: translateX(0);
      background-color: #00A443;
  }
}

.speed-in {
  position: absolute;
  bottom: 45px;
  left: 45px;
  z-index: 1;
  animation: fadeInOut1 10s infinite;
}

.speed-mind {
  position: absolute;
  bottom: 45px;
  left: 45px;
  z-index: 1;
  animation: fadeInOut2 10s infinite;
}

.speed-out {
  position: absolute;
  bottom: 45px;
  left: 45px;
  z-index: 1;
  animation: fadeInOut3 10s infinite;
}

@keyframes fadeInOut1 {
  0%, 33.33% { opacity: 1; }
  33.34%, 100% { opacity: 0; }
}

@keyframes fadeInOut2 {
  0%, 33.33% { opacity: 0; }
  33.34%, 66.66% { opacity: 1; }
  66.67%, 100% { opacity: 0; }
}

@keyframes fadeInOut3 {
  0%, 66.66% { opacity: 0; }
  66.67%, 100% { opacity: 1; }
}

.dots {
  display: inline-block;
  margin-left: 5px;
  font-size: 20px;
}

.dots span {
  display: inline-block;
  opacity: 0;
  animation: dotBounce 1s infinite;
}

.dots span:nth-child(1) {
  animation-delay: 0s;
}

.dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots span:nth-child(3) {
  animation-delay: 0.4s;
}


@keyframes dotBounce {
  0%, 100% {
      opacity: 0;
      transform: translateY(10px);
  }
  50% {
      opacity: 1;
      transform: translateY(0);
  }
}